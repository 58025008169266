body {
  display: flex;
  width: 100vw;
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex: 1;
}

.main-wrapper {
  display: flex;
  outline: none;
  background: red;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  padding: 5px;
  box-sizing: border-box;
}

.list {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  width: 300px;
}

p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 5px;
  padding: 0
}

.canvas-wrapper {
  flex: 1;
}

.fl1 {
  display: flex;
  flex: 1;
}

.fl2 {
  display: flex;
  flex: 2;
}
